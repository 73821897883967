// Product Demand template
// data = the whole data object
export default function productDemandTemplate(data, industry) {
  const m = data.messages;
  const industryIndex = data.settings.industries.indexOf(industry);
  const s = data.section1.items[industryIndex].items;
  // const navString = s.map( key => "<li>" + key.title + "</li>" ).join("");
            // <ul class="circle-nav">
            //   ${navString}
            // </ul>

  return `
          <div class="container is-fluid">
            <div class="bgr-top"> <img src="img/sections-backgrounds/${data.section1.items[industryIndex].img}"> </div>

              <div class="title-and-services">
                <div class="height-limiter">
                  <h2>${m[2].message}</h2>
                </div>

                <div id="pd-services">
                  ${pdScreenTemplate(data, s)}
                </div>
              </div>

          </div>
  `;
}

function pdScreenTemplate(data, s) {
  return s.map( ( key, index ) => `
              <div class="bgr-item bgr-item-${index}"> <img src="img/sections-backgrounds/${key.img}"> </div>
              <div class="height-limiter">
                <div class="services-screen">
                  <p>${data.section1.title}</p>
                  <h3>${key.title}</h3>
                  <ul>
                    ${wrapLi(key.items)}
                  </ul>
                </div>
              </div>` ).join("");
}

function wrapLi(d) {
  return d.map(function (key) {
    return `<li>${key}</li>`
  }).join("")
}
