// Digital Transformation template
// data = the whole data object
export default function digitalTransformationTemplate(data) {
  const m = data.messages,
        s = data.section0.items;

  return `
          <div class="container is-fluid">
            <div class="has-text-centered">
              <h2>${m[1].message}</h2>
            </div>

            <div class="columns is-mobile" id="dt-services">
              ${dtColTemplate(s)}
            </div>
          </div>
  `;
}

function dtColTemplate(data) {
  return data.map( key => `
              <div class="column">
                <img src="${key.icon}">
                <h3>${key.title}</h3>
                <ul>
                  ${wrapLi(key.items)}
                </ul>
              </div>` ).join("");


              // ${key.items =>
              //     key.items.map( ley => `<li>${ley}</li>` ).join("")}
}

function wrapLi(data) {
  return data.map(function (key) {
    return `<li>${key}</li>`
  }).join("")
}
