// Leftt Navigation rings template
export default function navPDTemplate() {
  return `
  <div id="nav-pd">
    <svg class="nav-wheel" xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1000 1000">

      <g class="w-button">
        <path class="st1" d="M809.8,417.1l61-16.4l27.5,13.5l17.1-25.5l61-16.4c21.8,80.9,23.2,168.5-0.1,255.3l-61-16.3l-27.5,13.5
          l-17.1-25.5l-61.1-16.4C824.8,526.6,823.9,469.7,809.8,417.1"/>
        <path class="st0" d="M809.8,417.1l61-16.4l27.5,13.5l17.1-25.5l61-16.4c21.8,80.9,23.2,168.5-0.1,255.3l-61-16.3l-27.5,13.5
          l-17.1-25.5l-61.1-16.4"/>
        <g class="w-text">
          <text transform="matrix(1 0 0 1 879.2278 509.87)" class="st3">PLAN</text>
          <rect x="842.2" y="440.3" class="st5" width="125" height="125"/>
        </g>
      </g>
      <g class="w-button">
        <path class="st1" d="M809.8,583.1l61,16.3l17.1,25.5l27.6-13.6l61,16.3c-21.6,81-64.2,157.5-127.7,221L804,804.1l-30.6-2.1
          l-2-30.7l-44.7-44.7C768,685.4,795.7,635.7,809.8,583.1"/>
        <path class="st0" d="M809.8,583.1l61,16.3l17.1,25.5l27.6-13.6l61,16.3c-21.6,81-64.2,157.5-127.7,221L804,804.1l-30.6-2.1
          l-2-30.7l-44.7-44.7"/>
        <g class="w-text">
          <text transform="matrix(1 0 0 1 800.3182 711.86)" class="st3">EVALUATE</text>
          <rect x="786.6" y="642.3" class="st5" width="125" height="125"/>
        </g>
      </g>
      <g class="w-button">
        <path class="st1" d="M726.7,726.9l44.7,44.6l2.1,30.6l30.7,2l44.7,44.6C789.6,908,714.4,953,627.6,976.2l-16.4-60.9l-25.5-17.1
          l13.6-27.6L583,809.6C639.4,794.6,688.2,765.4,726.7,726.9"/>
        <path class="st0" d="M726.7,726.9l44.7,44.6l2.1,30.6l30.7,2l44.7,44.6C789.6,908,714.4,953,627.6,976.2l-16.4-60.9l-25.5-17.1
          l13.6-27.6L583,809.6"/>
        <g class="w-text">
          <text transform="matrix(1 0 0 1 659.445 858.97)" class="st3">PREDICT</text>
          <rect x="637.4" y="789.4" class="st5" width="125" height="125"/>
        </g>
      </g>
      <g class="w-button">
        <path class="st1" d="M582.9,809.8l16.4,61l-13.5,27.5l25.5,17.1l16.4,61c-80.9,21.8-168.5,23.2-255.3-0.1l16.3-61l-13.5-27.5
          l25.5-17.1l16.4-61.1C473.4,824.8,530.3,823.9,582.9,809.8"/>
        <path class="st0" d="M582.9,809.8l16.4,61l-13.5,27.5l25.5,17.1l16.4,61c-80.9,21.8-168.5,23.2-255.3-0.1l16.3-61l-13.5-27.5
          l25.5-17.1l16.4-61.1"/>
        <g class="w-text">
          <text transform="matrix(1 0 0 1 474.9529 911.78)" class="st3">SELL</text>
          <rect x="434.7" y="842.2" class="st5" width="125" height="125"/>
        </g>
      </g>
      <g class="w-button">
        <path class="st1" d="M416.9,809.8l-16.3,61l-25.5,17.1l13.6,27.6l-16.3,61c-81-21.6-157.5-64.2-221-127.7l44.6-44.7l2.1-30.6
          l30.7-2l44.7-44.7C314.6,768,364.3,795.7,416.9,809.8"/>
        <path class="st0" d="M416.9,809.8l-16.3,61l-25.5,17.1l13.6,27.6l-16.3,61c-81-21.6-157.5-64.2-221-127.7l44.6-44.7l2.1-30.6
          l30.7-2l44.7-44.7"/>
        <g class="w-text">
          <text transform="matrix(1 0 0 1 258.8469 856.15)" class="st3">FULFILL</text>
          <rect x="232.7" y="786.6" class="st5" width="125" height="125"/>
        </g>
      </g>
      <g class="w-button">
        <path class="st1" d="M273.1,726.7l-44.6,44.7l-30.6,2.1l-2,30.7l-44.6,44.7C92,789.6,47,714.4,23.8,627.6l60.9-16.4l17.1-25.5
          l27.6,13.6l61.1-16.3C205.4,639.4,234.6,688.2,273.1,726.7"/>
        <path class="st0" d="M273.1,726.7l-44.6,44.7l-30.6,2.1l-2,30.7l-44.6,44.7C92,789.6,47,714.4,23.8,627.6l60.9-16.4l17.1-25.5
          l27.6,13.6l61.1-16.3"/>
        <g class="w-text">
          <text transform="matrix(1 0 0 1 122.5693 706.97)" class="st3">PLAN</text>
          <rect x="85.6" y="637.4" class="st5" width="125" height="125"/>
        </g>
      </g>
      <g class="w-button">
        <path class="st1" d="M190.2,582.9l-61,16.4l-27.5-13.5l-17.1,25.5l-61,16.4C1.9,546.8,0.5,459.2,23.8,372.4l61,16.3l27.5-13.5
          l17.1,25.5l61.1,16.4C175.2,473.4,176.1,530.3,190.2,582.9"/>
        <path class="st0" d="M190.2,582.9l-61,16.4l-27.5-13.5l-17.1,25.5l-61,16.4C1.9,546.8,0.5,459.2,23.8,372.4l61,16.3l27.5-13.5
          l17.1,25.5l61.1,16.4"/>
        <g class="w-text">
          <text transform="matrix(1 0 0 1 58.9218 504.22)" class="st3">FULFILL</text>
          <rect x="32.8" y="434.7" class="st5" width="125" height="125"/>
        </g>
      </g>
      <g class="w-button">
        <path class="st1" d="M190.2,416.9l-61-16.3l-17.1-25.5l-27.6,13.6l-61-16.3c21.6-81,64.2-157.5,127.7-221l44.7,44.6l30.6,2.1
          l2,30.7l44.7,44.7C232,314.6,204.3,364.3,190.2,416.9"/>
        <path class="st0" d="M190.2,416.9l-61-16.3l-17.1-25.5l-27.6,13.6l-61-16.3c21.6-81,64.2-157.5,127.7-221l44.7,44.6l30.6,2.1
          l2,30.7l44.7,44.7"/>
        <g class="w-text">
          <text transform="matrix(1 0 0 1 125.3906 302.24)" class="st3">PLAN</text>
          <rect x="88.4" y="232.7" class="st5" width="125" height="125"/>
        </g>
      </g>
      <g class="w-button">
        <path class="st1" d="M273.3,273.1l-44.7-44.6l-2.1-30.6l-30.7-2l-44.7-44.6C210.4,92,285.6,47,372.4,23.8l16.4,60.9l25.5,17.1
          l-13.6,27.6l16.3,61.1C360.6,205.4,311.8,234.6,273.3,273.1"/>
        <path class="st0" d="M273.3,273.1l-44.7-44.6l-2.1-30.6l-30.7-2l-44.7-44.6C210.4,92,285.6,47,372.4,23.8l16.4,60.9l25.5,17.1
          l-13.6,27.6l16.3,61.1"/>
        <g class="w-text">
          <text transform="matrix(1 0 0 1 252.2921 155.12)" class="st3">EVALUATE</text>
          <rect x="237.6" y="85.6" class="st5" width="125" height="125"/>
        </g>
      </g>
      <g class="w-button">
        <path class="st1" d="M417.1,190.2l-16.4-61l13.5-27.5l-25.5-17.1l-16.4-61c80.9-21.8,168.5-23.2,255.3,0.1l-16.3,61l13.5,27.5
          l-25.5,17.1l-16.4,61.1C526.6,175.2,469.7,176.1,417.1,190.2"/>
        <path class="st0" d="M417.1,190.2l-16.4-61l13.5-27.5l-25.5-17.1l-16.4-61c80.9-21.8,168.5-23.2,255.3,0.1l-16.3,61l13.5,27.5
          l-25.5,17.1l-16.4,61.1"/>
        <g class="w-text">
          <text transform="matrix(1 0 0 1 462.3407 102.31)" class="st3">PREDICT</text>
          <rect x="440.3" y="32.8" class="st5" width="125" height="125"/>
        </g>
      </g>
      <g class="w-button">
        <path class="st1" d="M583.1,190.2l16.3-61l25.5-17.1l-13.6-27.6l16.3-61c81,21.6,157.5,64.2,221,127.7L804.1,196l-2.1,30.6
          l-30.7,2l-44.7,44.7C685.4,232,635.7,204.3,583.1,190.2"/>
        <path class="st0" d="M583.1,190.2l16.3-61l25.5-17.1l-13.6-27.6l16.3-61c81,21.6,157.5,64.2,221,127.7L804.1,196l-2.1,30.6
          l-30.7,2l-44.7,44.7"/>
        <g class="w-text">
          <text transform="matrix(1 0 0 1 682.5858 157.95)" class="st3">10 SELL</text>
          <rect x="642.3" y="88.4" class="st5" width="125" height="125"/>
        </g>
      </g>
      <g class="w-button">
        <path class="st1" d="M726.9,273.3l44.6-44.7l30.6-2.1l2-30.7l44.6-44.7C908,210.4,953,285.6,976.2,372.4l-60.9,16.4l-17.1,25.5
          l-27.6-13.6L809.6,417C794.6,360.6,765.4,311.8,726.9,273.3"/>
        <path class="st0" d="M726.9,273.3l44.6-44.7l30.6-2.1l2-30.7l44.6-44.7C908,210.4,953,285.6,976.2,372.4l-60.9,16.4l-17.1,25.5
          l-27.6-13.6L809.6,417"/>
        <g class="w-text">
          <text transform="matrix(1 0 0 1 810.5807 307.12)" class="st3">11 FULFILL</text>
          <rect x="789.4" y="237.6" class="st5" width="125" height="125"/>
        </g>
      </g>
    </svg>
  </div>
  `;
}
