import gsap from 'gsap';
import Data from './data/data';
import Section from './section';
import BuildSections from './build-sections';
import NodeFromString from './utils/node-from-string';

gsap.defaults({ ease: 'sine.inOut' });

export default class Navigation {
  constructor() {
    // Import the data

    // from data.js
    // this.data = new Data().data;
    // this.init();

    // from data.json
    fetch(jsonUrl)
      .then(response => response.json())
      .then(parsed => {
        this.data = parsed;
        this.init();
      });

    // preloader
    window.onload = function() {
      // const introDuration = 2;
      const introDuration = 0;

      gsap.timeline()
        .to('#top-container', { opacity: 1, display: 'block' }, introDuration)
        .to('#section-intro', { opacity: 0, display: 'none' }, '<')
    };
  }

  init() {

    // increaseWheelFontSize()

    // The industry is selected on the very first screen, "menu",
    // and updated in "settings". It determines the content of the
    // "product-demand" and "customer-engagement" sections.
    this.industry = this.data.settings.industries[0];

    this.navElem = document.querySelector('nav.navbar');

    // All the sections that will be filled with data by "BuildSections"
    this.sectionElems = document.querySelectorAll('#section-settings, #section-menu, #sections section');

    // Build and attach HTML from data
    this.build = new BuildSections(this.sectionElems, this.industry, this.data);
    document.querySelector('#instructional-cues').innerHTML = this.data.navWheelHelp;
    document.querySelector('#footer-privacy').innerHTML = this.data.footerLinks[0];
    document.querySelector('#footer-terms').innerHTML = this.data.footerLinks[1];
    document.querySelector('#footer-copyright').innerHTML = this.data.footerLinks[2];
    document.querySelectorAll('#footer-nav a').forEach( (elem, i) => {
      elem.innerHTML = this.data.footerLinks[i];
    })


    // Create all the sections based on the sectionElems
    this.sections = [];
    for (var i = 0; i < this.sectionElems.length; i++) {
      let s = new Section(this.sectionElems[i], i);
      this.sections.push(s);
    }

    // // set images widths
    // let images = document.querySelectorAll('#section-product-demand img, #section-customer-engagement img');
    // console.log(images)
    // images.forEach( img => {
    //   console.log(img.width);
    // })

    // the settings nav link elems
    this.settingsLinks = document.querySelectorAll('#industry-selector a');

    this.updateContentCount = 0;
    this.updateContent(this.data.settings.industries[0]);

    // Menu is the first screen
    this.currentSection = 4;

    // Register event listeners
    this.eventListeners()

    // this.showSection(1);
    this.showSection(1);
    // gsap.set('#section-menu', {display: 'none'})

  }

  updateContent(industry) {
    const index = this.data.settings.industries.indexOf(industry);
    this.industry = industry;
    // console.log('this.industry: ' + this.industry + ' index: ' + index );
    this.settingsLinks.forEach( link => link.classList.remove('active'));
    this.settingsLinks[index].classList.add('active');

    // update the home page h1
    document.querySelector('#section-home .title h1').innerHTML = this.data.messages[0].titles[index];

    // update the home page personnage
    document.querySelector('#section-home').classList.remove('c0', 'c1', 'c2', 'c3');
    document.querySelector('#section-home').classList.add('c'+index);

    // update wheel text
    const pdWheelTexts = document.querySelectorAll('#nav-pd .st3'),
          ceWheelTexts = document.querySelectorAll('#nav-ce .st3'),

          pdNavStrings = this.data.section1["nav wheel links"][this.industry],
          ceNavStrings = this.data.section2["nav wheel links"][this.industry],

          // initial positions
          // en
          pdStartWidths = [41.78125, 81.484375, 66.671875, 32, 70],
          ceStartWidths = [41.78125, 55.109375, 90, 58.65625, 32];
          // fr
          // pdStartWidths = [40, 85, 75, 32, 63],
          // ceStartWidths = [45, 74, 115, 64, 50];
          // de
          // pdStartWidths = [37, 78, 58, 23, 63],
          // ceStartWidths = [26, 57, 102, 43, 24];
          // it
          // pdStartWidths = [25, 85, 66, 33, 65],
          // ceStartWidths = [26, 57, 102, 53, 30];
          // pt
          // pdStartWidths = [27, 82, 66, 31, 75],
          // ceStartWidths = [26, 57, 102, 53, 34];
          // ru
          // pdStartWidths = [38, 85, 71, 32, 66],
          // ceStartWidths = [33, 61, 119, 53, 26];
          // es
          // pdStartWidths = [40, 80, 58, 23, 60],
          // ceStartWidths = [26, 57, 102, 52, 17];

    for (var i = 0; i < 5; i++) {
      let iw = i<3 ? i : i+7;

      // console.log(this.updateContentCount);
      // console.log('a', pdWheelTexts[iw].innerHTML, pdWheelTexts[iw].getBBox().width);

      if (this.updateContentCount === 1) {
      // if (false) {
        pdWheelTexts[iw].innerHTML  = pdNavStrings[i].toUpperCase();
        ceWheelTexts[iw].innerHTML  = ceNavStrings[i].toUpperCase();

        let pddW1 = (pdWheelTexts[iw].getBBox().width - pdStartWidths[i])/2;
        let cedW1 = (ceWheelTexts[iw].getBBox().width - ceStartWidths[i])/2;

        // console.log('i', i, pdNavStrings[i], pdWheelTexts[iw], '| old ', pdStartWidths[i], '| new ', pdWheelTexts[iw].getBBox().width, '| ∆ ', pddW1);

        // gsap.set(pdWheelTexts[iw], { x: '-=56'})
        gsap.set(pdWheelTexts[iw], { x: '-='+pddW1})
        gsap.set(ceWheelTexts[iw], { x: '-='+cedW1})
      } else {
        let pdPrevW1 = pdWheelTexts[iw].getBBox().width;
        let cePrevW1 = ceWheelTexts[iw].getBBox().width;

        pdWheelTexts[iw].innerHTML  = pdNavStrings[i].toUpperCase();
        ceWheelTexts[iw].innerHTML  = ceNavStrings[i].toUpperCase();

        let pddW1 = (pdWheelTexts[iw].getBBox().width - pdPrevW1)/2;
        let cedW1 = (ceWheelTexts[iw].getBBox().width - cePrevW1)/2;

        gsap.set(pdWheelTexts[iw], { x: '-='+pddW1})
        gsap.set(ceWheelTexts[iw], { x: '-='+cedW1})
      }
    }
    this.updateContentCount++;

    // rebuild Product Demand and Customer Engagement sections
    gsap.to(['#section-product-demand .container', '#section-customer-engagement .container'], { opacity: 0, onComplete: () => {

      this.sectionElems[4].innerHTML = '';
      this.sectionElems[5].innerHTML = '';

      this.build.rebuild(this.sectionElems, this.industry, this.data);

      gsap.to(['#section-product-demand .container', '#section-customer-engagement .container'], { opacity: 1 });
    } })

    // this.sectionElems[4].querySelector('.container')
    // this.sectionElems[5]
  }

  showSection(i, callbackThat) {
    // this fn will sometimes be called back from the Sections,
    // so we have to check if 'this' exists or has to be set from the argument
    let that;
    if (this == null){
      that = callbackThat;
    } else {
      that = this;
    }

    let next = that.sections[i];

    if (i === that.currentSection) {
      return;
    }

    if (i !== 1) {
      that.navElem.classList.remove('hidden');
    }

    // Send current section as param so "show" can hide it when animation is done
    next.show(that.sections[that.currentSection]);

    // Update the currentSection var
    that.currentSection = i;
  }

  showNavigation() {
    const elem = document.querySelector('#section-settings');
    gsap.to(elem, {right: 0, duration: 0.5})
  }
  hideNavigation() {
    const elem = document.querySelector('#section-settings');
    gsap.to(elem, {right: "-=30%", duration: 0.5})
  }


  // register event listeners on all the links
  eventListeners() {

    // #section-menu – The first screen the visitor sees
    // Clicking a link updates content for Product Demand and Customer Engagement
    // and then shows the home section
    const menuLinks = document.querySelectorAll('#section-menu a');
    menuLinks.forEach( (item, clickedIndex) => {
      item.addEventListener( 'click', e => {
        e.preventDefault();

        const that = this;

        menuLinks.forEach( (item, index) => {
          if (index < clickedIndex) {
            gsap.timeline()
              .to(item, {y: '-=30', opacity: 0, duration: .5})
          } else if (index > clickedIndex) {
            gsap.timeline()
              .to(item, {y: '+=30', opacity: 0, duration: .5})
          } else {
            that.updateContent(item.dataset.industry);
            gsap.timeline()
              // .to(item, {opacity: 0, delay: 1, duration: .5})
              .to('#section-menu', {opacity: 0, delay: 1, duration: .5})
              .to('#section-menu', { display: 'block', duration: 0, callback: () => {
                that.showSection(2); // Home section
              }}, '<')
          }
        } );
      } );
    } );

    // #section-setings - The sidebar navigation links
    // Clicking a link updates the content for Product Demand and Customer Engagement
    // without changing pages
    this.settingsLinks.forEach( item => {
      item.addEventListener( 'click', e => {
        e.preventDefault();
        this.hideNavigation();

        this.updateContent(item.dataset.industry);

        // reset wheel links active state and home text status
        pdwCurrentIndex = 0;
        pdwPrevIndex = 0;
        pdHome = true;

        cewCurrentIndex = 0;
        cewPrevIndex = 0;
        ceHome = true;

        pdWheelLinks.forEach( item => item.classList.remove('active') );
        ceWheelLinks.forEach( item => item.classList.remove('active') );

        gsap.set('#nav-pd .nav-wheel', { rotation: 0, transformOrigin: "50% 50%"})
        gsap.set('#nav-pd .w-text',    { rotation: 0, transformOrigin: "50% 50%"})
        gsap.set('#nav-ce .nav-wheel', { rotation: 0, transformOrigin: "50% 50%"})
        gsap.set('#nav-ce .w-text',    { rotation: 0, transformOrigin: "50% 50%"})

      } );
    } );

    // .navbar links
    // Home
    const homeMenuLink = document.querySelector('#home-menu-link');
    homeMenuLink.addEventListener( 'click', e => {
      // console.log('home clicked');
      const that = this;
      if (this.currentSection === 3) {
        // console.log('home from 3');
        this.sections[3].playOut(this.showSection, 2, that);
      }
      if (this.currentSection === 4) {
        // console.log('home from 4');
        this.sections[4].playOut(this.showSection, 2, that);
      }
      if (this.currentSection === 5) {
        // console.log('home from 5');
        this.sections[5].playOut(this.showSection, 2, that);
      }
      // this.showSection(2); // Home section
    } );

    // Back
    const backMenuLink = document.querySelector('#back-menu-link');
    backMenuLink.addEventListener( 'click', e => {
      const that = this;
      if (this.currentSection === 3) {
        // console.log('home from 3');
        this.sections[3].playOut(this.showSection, 2, that);
      }
      if (this.currentSection === 4) {
        // console.log('home from 4');
        this.sections[4].playOut(this.showSection, 2, that);
      }
      if (this.currentSection === 5) {
        // console.log('home from 5');
        this.sections[5].playOut(this.showSection, 2, that);
      }
    } );

    // Settings shows #section-setings in a sidebar
    const settingsMenuLink = document.querySelector('#settings-menu-link');
    settingsMenuLink.addEventListener( 'click', e => {
      // console.log('settings clicked');
      this.showNavigation();
    } );

    // Close Settings hides #section-setings
    const closeSettingsLink = document.querySelector('#close-settings-link');
    closeSettingsLink.addEventListener( 'click', e => {
      // console.log('close settings clicked');
      this.hideNavigation();
    } );

    // #section-home - The home page
    // Clicking a link shows one of the three sub-sections:
    // #section-digital-transformation, #section-product-demand, #section-customer-engagement
    const homeLinks = document.querySelectorAll('#section-home a');
    homeLinks.forEach( (item, index) => {
      item.addEventListener( 'click', e => {
        e.preventDefault();
        const that = this;

        // show section 3, 4 or 5, but 3 and 4 switch places
        // because dt is before pd in the html source
        let i = index === 0 ? 4 : index === 1 ? 3 : 5;

        if (i === 3) {
          // console.log('nav i=3');
          this.sections[2].playOut(this.showSection, 3, that);
        }
        if (i === 4) {
          // console.log('nav i=4');
          this.sections[2].playOut(this.showSection, 4, that);
        }
        if (i === 5) {
          // console.log('nav i=5');
          this.sections[2].playOut(this.showSection, 5, that);
        }
      } );
    } );

    // Product Demand Nav wheel links
    let pdwAnimatingWheel = false,
        pdwCurrentIndex = 0,
        pdwPrevIndex = 0,
        pdHome = true,
        pdStarting = true;

    const pdContainer = document.querySelector('#nav-pd'),
          pdWheelLinks = pdContainer.querySelectorAll('.w-button'),
          // pdScreens = document.querySelectorAll('#section-product-demand .services-screen'),
          pdNavTexts = pdContainer.querySelectorAll('.st3');

    pdWheelLinks.forEach( (item, index) => {
      item.addEventListener( 'click', e => {
        if (pdwAnimatingWheel) {
          return;
        }
        pdwAnimatingWheel = true;

        // Hide the instructional cues
        gsap.to('#instructional-cues', { duration: .5, opacity: 0, display: 'none' });

        pdWheelLinks.forEach( item => item.classList.remove('active') );
        item.classList.add('active');

        const tl = gsap.timeline({delay: .2}),
              navStrings = this.data.section1["nav wheel links"][this.industry],
              len = navStrings.length;
        let   indexD = index - pdwPrevIndex;
        const rotateAmount = -30 * indexD;

        if (indexD >= 9 ) {
          indexD -= 7;
        } else if (indexD <= -9) {
          indexD += 7;
        }

        if (indexD < 0) {
          indexD += len;
        } else if (indexD > len) {
          indexD -= len;
        }

        const navIndex = (pdwCurrentIndex + indexD) % len;

        if ((pdStarting && index > 7) || (index - pdwPrevIndex < 0)) {
          if (index > 10) {
            pdStarting = false;
            console.log('starting done');
          }
          // console.log((index+11)%12,  (index+10)%12, (index+9)%12);
// console.log('a | index', index);
          let prevW12 = pdNavTexts[(index+ 12)%12].getBBox().width;
          let prevW11 = pdNavTexts[(index+ 11)%12].getBBox().width;
          let prevW10 = pdNavTexts[(index+ 10)%12].getBBox().width;

          pdNavTexts[(index+12)%12].innerHTML  = navStrings[(navIndex+len-0) % len].toUpperCase();
          pdNavTexts[(index+11)%12].innerHTML  = navStrings[(navIndex+len-1) % len].toUpperCase();
          pdNavTexts[(index+10)%12].innerHTML  = navStrings[(navIndex+len-2) % len].toUpperCase();

// console.log(prevW12, pdNavTexts[(index+12)%12].innerHTML, pdNavTexts[(index+ 12)%12].getBBox().width);
// console.log(prevW11, pdNavTexts[(index+11)%12].innerHTML, pdNavTexts[(index+ 11)%12].getBBox().width);
// console.log(prevW10, pdNavTexts[(index+10)%12].innerHTML, pdNavTexts[(index+ 10)%12].getBBox().width);

          let dW12 = (pdNavTexts[(index+ 12)%12].getBBox().width - prevW12)/2;
          let dW11 = (pdNavTexts[(index+ 11)%12].getBBox().width - prevW11)/2;
          let dW10 = (pdNavTexts[(index+ 10)%12].getBBox().width - prevW10)/2;

          tl.set(pdNavTexts[(index+ 12)%12], { x: '-='+dW12 })
          tl.set(pdNavTexts[(index+ 11)%12], { x: '-='+dW11 })
          tl.set(pdNavTexts[(index+ 10)%12], { x: '-='+dW10 })
        } else {
// console.log('b | index', index);
          let prevW0 = pdNavTexts[(index+ 0)%12].getBBox().width;
          let prevW1 = pdNavTexts[(index+ 1)%12].getBBox().width;
          let prevW2 = pdNavTexts[(index+ 2)%12].getBBox().width;

          pdNavTexts[(index+0)%12].innerHTML  = navStrings[(navIndex+0) % len].toUpperCase();
          pdNavTexts[(index+1)%12].innerHTML  = navStrings[(navIndex+1) % len].toUpperCase();
          pdNavTexts[(index+2)%12].innerHTML  = navStrings[(navIndex+2) % len].toUpperCase();

// console.log(prevW0, pdNavTexts[(index+ 0)%12].getBBox().width);
// console.log(prevW1, pdNavTexts[(index+ 1)%12].getBBox().width);
// console.log(prevW2, pdNavTexts[(index+ 2)%12].getBBox().width);

          let dW0 = (pdNavTexts[(index+ 0)%12].getBBox().width - prevW0)/2;
          let dW1 = (pdNavTexts[(index+ 1)%12].getBBox().width - prevW1)/2;
          let dW2 = (pdNavTexts[(index+ 2)%12].getBBox().width - prevW2)/2;

          tl.set(pdNavTexts[(index+ 0)%12], { x: '-='+dW0})
          tl.set(pdNavTexts[(index+ 1)%12], { x: '-='+dW1})
          tl.set(pdNavTexts[(index+ 2)%12], { x: '-='+dW2})
        }

        // console.log( 'index, navIndex', index, navIndex );
        if (rotateAmount !== 0) {
          tl.to('#nav-pd .nav-wheel', { duration: 1, rotation: "+="+rotateAmount+'_short', transformOrigin: "50% 50%", ease: "power2.inout" }, 0)
          tl.to('#nav-pd .w-text', { duration: 1, rotation: '-='+rotateAmount+'_short', transformOrigin: "50% 50%", ease: "power2.inout" }, 0)
        }

        if (pdHome) {
          pdHome = false;
          tl.to('#section-product-demand .title-and-services h2', { duration: 1, opacity: 0, display: 'none' }, 0)
        } else {
          tl.to(document.querySelectorAll('#section-product-demand .services-screen')[pdwCurrentIndex], { duration: 1, opacity: 0, display: 'none' }, 0)
        }
        tl
          .to('#section-product-demand .bgr-top, #section-product-demand .bgr-item', { duration: 1, scale: 1.3, opacity: 0, ease: "power2.in" }, 0)
          .fromTo(document.querySelectorAll('#section-product-demand .bgr-item')[navIndex], { scale: 0.8, opacity: 0 }, { duration: 1, scale: 1, opacity: 1, ease: "power2.out" }, 1)

          .to(document.querySelectorAll('#section-product-demand .services-screen')[navIndex], { duration: 1, opacity: 1, display: 'block', onComplete: () => pdwAnimatingWheel = false }, 1)

        pdwPrevIndex = index;
        pdwCurrentIndex = navIndex;
      } );
    } );

    // ce nav wheeel
    let cewAnimatingWheel = false,
        cewCurrentIndex = 0,
        cewPrevIndex = 0,
        ceHome = true,
        ceStarting = true;

    const ceContainer = document.querySelector('#nav-ce'),
          ceWheelLinks = ceContainer.querySelectorAll('.w-button'),
          ceNavTexts = ceContainer.querySelectorAll('.st3');

    ceWheelLinks.forEach( (item, index) => {
      item.addEventListener( 'click', e => {
        if (cewAnimatingWheel) {
          return;
        }
        cewAnimatingWheel = true;

        // hide the instructional cues
        gsap.to('#instructional-cues', { duration: .5, opacity: 0, display: 'none' });

        ceWheelLinks.forEach( item => item.classList.remove('active') );
        item.classList.add('active');

        const tl = gsap.timeline({delay: .2}),
              navStrings = this.data.section2["nav wheel links"][this.industry],
              len = navStrings.length;
        let  indexD = index - cewPrevIndex;
        const rotateAmount = -30 * indexD;

        if (indexD >= 9 ) {
          indexD -= 7;
        } else if (indexD <= -9) {
          indexD += 7;
        }

        if (indexD < 0) {
          indexD += len;
        } else if (indexD > len) {
          indexD -= len;
        }

        const navIndex = (cewCurrentIndex + indexD) % len;

        if ((ceStarting && index > 7) || (index - cewPrevIndex < 0)) {
          if (index > 10) {
            ceStarting = false;
          }

          let prevW12 = ceNavTexts[(index+ 12)%12].getBBox().width;
          let prevW11 = ceNavTexts[(index+ 11)%12].getBBox().width;
          let prevW10 = ceNavTexts[(index+ 10)%12].getBBox().width;

          ceNavTexts[(index+12)%12].innerHTML  = navStrings[(navIndex+len-0) % len].toUpperCase();
          ceNavTexts[(index+11)%12].innerHTML  = navStrings[(navIndex+len-1) % len].toUpperCase();
          ceNavTexts[(index+10)%12].innerHTML  = navStrings[(navIndex+len-2) % len].toUpperCase();

          let dW12 = (ceNavTexts[(index+ 12)%12].getBBox().width - prevW12)/2;
          let dW11 = (ceNavTexts[(index+ 11)%12].getBBox().width - prevW11)/2;
          let dW10 = (ceNavTexts[(index+ 10)%12].getBBox().width - prevW10)/2;

          gsap.set(ceNavTexts[(index+ 12)%12], { x: '-='+dW12 })
          gsap.set(ceNavTexts[(index+ 11)%12], { x: '-='+dW11 })
          gsap.set(ceNavTexts[(index+ 10)%12], { x: '-='+dW10 })
        } else {
          let prevW0 = ceNavTexts[(index+ 0)%12].getBBox().width;
          let prevW1 = ceNavTexts[(index+ 1)%12].getBBox().width;
          let prevW2 = ceNavTexts[(index+ 2)%12].getBBox().width;

          ceNavTexts[(index+0)%12].innerHTML  = navStrings[(navIndex+0) % len].toUpperCase();
          ceNavTexts[(index+1)%12].innerHTML  = navStrings[(navIndex+1) % len].toUpperCase();
          ceNavTexts[(index+2)%12].innerHTML  = navStrings[(navIndex+2) % len].toUpperCase();

          let dW0 = (ceNavTexts[(index+ 0)%12].getBBox().width - prevW0)/2;
          let dW1 = (ceNavTexts[(index+ 1)%12].getBBox().width - prevW1)/2;
          let dW2 = (ceNavTexts[(index+ 2)%12].getBBox().width - prevW2)/2;

          gsap.set(ceNavTexts[(index+ 0)%12], { x: '-='+dW0})
          gsap.set(ceNavTexts[(index+ 1)%12], { x: '-='+dW1})
          gsap.set(ceNavTexts[(index+ 2)%12], { x: '-='+dW2})
        }

        // console.log('index, navIndex', index, navIndex );

        if (rotateAmount !== 0) {
          tl.to('#nav-ce .nav-wheel', {duration: 1, rotation: "+="+rotateAmount+'_short', transformOrigin: "50% 50%", ease: "power2.inout"}, 0)
          tl.to('#nav-ce .w-text', {duration: 1, rotation: '-='+rotateAmount+'_short', transformOrigin: "50% 50%", ease: "power2.inout"}, 0)
        }

        if (ceHome) {
          ceHome = false;
          tl.to('#section-customer-engagement .title-and-services h2', { duration: 1, opacity: 0, display: 'none' }, 0)
        } else {
          tl.to(document.querySelectorAll('#section-customer-engagement .services-screen')[cewCurrentIndex], { duration: 1, opacity: 0, display: 'none' }, 0);
        }
        tl
          .to('#section-customer-engagement .bgr-top, #section-customer-engagement .bgr-item', { duration: 1, scale: 1.3, opacity: 0, ease: "power2.in" }, 0)
          .fromTo(document.querySelectorAll('#section-customer-engagement .bgr-item')[navIndex], { scale: 0.8, opacity: 0 }, { duration: 1, scale: 1, opacity: 1, ease: "power2.out" }, 1)

          .to(document.querySelectorAll('#section-customer-engagement .services-screen')[navIndex], { duration: 1, opacity: 1, display: 'block', onComplete: () => cewAnimatingWheel = false }, 1);

        cewPrevIndex = index;
        cewCurrentIndex = navIndex;
      } );
    } );

    // Overview Video lightbox
    const ovLinkElem = document.querySelector('#overview-video'),
          ovLinkHref = ovLinkElem.getAttribute("href"),
          ovLightboxElem = document.querySelectorAll('#lightbox'),
          ovWrapperElem = document.querySelector('#lightbox .wrapper'),
          ovLightboxCloseElems = document.querySelectorAll('#close-lightbox-link, #lightbox .shadow'),
          ovIframeTemplate = () => `<iframe src="${this.data.settings.overviewVideoLink}" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>`;

    // ovLinkElem.addEventListener( 'click', e => {
    //   e.preventDefault();
    //   new NodeFromString(ovWrapperElem, ovIframeTemplate, '', {});

    //   gsap.to(ovLightboxElem, { duration: 0.5, opacity: 1, display: 'block'});
    //   this.hideNavigation();
    // });
    ovLightboxCloseElems.forEach( elem => {
      elem.addEventListener( 'click', e => {
        e.preventDefault();
        gsap.to(ovLightboxElem, { duration: 0.5, opacity: 0, display: 'none'});
        ovWrapperElem.innerHTML = '';
      });
    });

    // Learn More Videos lightbox
    const ovlmLinkElem = document.querySelector('#watch-lm-link'),
          ovlmInstructionElem = document.querySelector('#watch-lm-instruction-link'),
          ovlmLightboxElem = document.querySelectorAll('#lightbox-lm'),
          ovlmLightboxCloseElems = document.querySelectorAll('#close-lightbox-lm-link, #lightbox-lm .shadow'),

          ovlmLinks = document.querySelectorAll('#lightbox-lm a.thumb');

    let allVideosClickHandler = e => {
      e.preventDefault();

      gsap.to(ovlmLightboxElem, { duration: 0.5, opacity: 1, display: 'block' });
      gsap.to(ovlmInstructionElem, { duration: 0.5, opacity: 0 })
      this.hideNavigation();
    }

    ovLinkElem.addEventListener( 'click', allVideosClickHandler);
    ovlmLinkElem.addEventListener( 'click', allVideosClickHandler);
    ovlmLightboxCloseElems.forEach( elem => {
      elem.addEventListener( 'click', e => {
        e.preventDefault();
        gsap.to(ovlmLightboxElem, { duration: 0.5, opacity: 0, display: 'none' });
      });
    });
    ovlmLinks.forEach( elem => {
      elem.addEventListener( 'click', e => {
        e.preventDefault();
        const link = elem.getAttribute("href");
        const template = () => `<iframe src="${link}" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>`;
        new NodeFromString(ovWrapperElem, template, '', {});
        gsap.to(ovLightboxElem, { duration: 0.5, opacity: 1, display: 'block'});
      });
    });

    // Language selector
    const langElem = document.querySelector('#language-selector'),
          langSelected = langElem.querySelector('.selected'),
          langLinksUl = langElem.querySelector('ul'),
          langLinks = langElem.querySelectorAll('li');

    langSelected.addEventListener('click', e => {
      gsap.to(['#industry-selector', '#overview-video'], { opacity: 0 });
      gsap.to(langSelected, { height: 0 });
      gsap.to(langLinksUl, { height: 248});
      gsap.to(ovLinkElem, { y: '-=194' });
      gsap.to('.close-lang-selector', { opacity: 1 })
      gsap.to(closeSettingsLink, { opacity: 0 })
    });
console.log(langElem.querySelectorAll('.sel a, .close-lang-selector'));
    langElem.querySelectorAll('.sel a, .close-lang-selector').forEach(elem => {
      elem.addEventListener('click', e => {
        e.preventDefault();
        gsap.to(['#industry-selector', '#overview-video'], { opacity: 1 });
        gsap.to(langSelected, { height: 'auto' });
        gsap.to(langLinksUl, { height: 0 });
        gsap.to(ovLinkElem, { y: 0 });
        gsap.to('.close-lang-selector', { opacity: 0 })
        gsap.to(closeSettingsLink, { opacity: 1 })
      })
    })
  }
}


function wheelToSectionIndex(i) {
  // 1 2 3 4 5 1 5 1 2 3 4 5 // wheel text
  // 0 1 2 3 4 5 6 7 8 9 0 1 // index
  // 0 1 2 3 4 0 4 0 1 2 3 4 // i

  if (i <= 4) {
    return i;
  } else if (i === 5) {
    return 0;
  } else if (i === 6) {
    return 4;
  } else {
    return i - 7;
  }
}

function increaseWheelFontSize() {
  const pdWheelTexts = document.querySelectorAll('#nav-pd .st3'),
        ceWheelTexts = document.querySelectorAll('#nav-ce .st3');

  for (var i = 0; i < 12; i++) {
    let pdPrevW = pdWheelTexts[i].getBBox().width;
    let cePrevW = ceWheelTexts[i].getBBox().width;

    gsap.set(pdWheelTexts[i], {fontSize: '24px'})
    gsap.set(ceWheelTexts[i], {fontSize: '24px'})

    let pddW = (pdWheelTexts[i].getBBox().width - pdPrevW)/2;
    let cedW = (ceWheelTexts[i].getBBox().width - cePrevW)/2;

    gsap.set(pdWheelTexts[i], { x: '-='+pddW})
    gsap.set(ceWheelTexts[i], { x: '-='+cedW})
  }
}
