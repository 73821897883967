// Create and attach a dom node
export default class NodeFromString {
  constructor(elem, template, industry, data) {
    elem.appendChild( htmlToElement( template(data, industry) ) );
  }
}

// DOM node from string
function htmlToElement(htmlString) {
  const template = document.createElement('template');
  htmlString = htmlString.trim();
  template.innerHTML = htmlString;
  return template.content.firstChild;
}
