// Settings template
// data = "settings"
export default function settingsTemplate(data) {
  return `
          <div class="container">
            <a href="#" id="close-settings-link"><img src="img/icons/menu/x.svg"></a>
            <ul id="industry-selector">
              <li><a href="#" data-industry="${data.industries[0]}">${data.industries[0]}</a></li>
              <li><a href="#" data-industry="${data.industries[1]}">${data.industries[1]}</a></li>
              <li><a href="#" data-industry="${data.industries[2]}">${data.industries[2]}</a></li>
              <li><a href="#" data-industry="${data.industries[3]}">${data.industries[3]}</a></li>
            </ul>
            <a href="${data.overviewVideoLink}" id="overview-video" target="_blank"><img src="img/icons/menu/play.svg">${data.overviewVideoText}</a>
            <div id="language-selector">
              <div class="icon-wrapper">
                <img src="img/icons/menu/language.svg">
              </div>
              <div class="selected">English</div>
              <div class="close-lang-selector"><img src="img/icons/menu/x.svg"></div>
              <ul>
                <li class="sel"><a href="/">English</a></li>
                <li><a href="/fr/">French</a></li>
                <li><a href="/de/">German</a></li>
                <li><a href="/it/">Italian</a></li>
                <li><a href="/pt/">Portuguese</a></li>
                <li><a href="/ru/">Russian</a></li>
                <li><a href="/es/">Spanish</a></li>
              </ul>
            </div>
          </div>
  `;
}
