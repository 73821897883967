// Learn More Video lightbox
// data = the whole data object
export default function lmVideoTemplate(data) {
  const title = data.lmVideos.title,
         v = data.lmVideos.videos;

  return `
          <div>
            <h2 class="has-text-centered">${title}</h2>
            <div class="columns">
              <div class="column">
                <a class="thumb" href="${v[0].videoLink}">
                  <img class="vthumb" src="${v[0].thumb}">
                  <img class="play" src="img/icons/menu/watch.svg">
                </a>
                ${v[0].title}
              </div>
              <div class="column">
                <a class="thumb" href="${v[1].videoLink}">
                  <img class="vthumb" src="${v[1].thumb}">
                  <img class="play" src="img/icons/menu/watch.svg">
                </a>
                ${v[1].title}
              </div>
              <div class="column">
                <a class="thumb" href="${v[2].videoLink}">
                  <img class="vthumb" src="${v[2].thumb}">
                  <img class="play" src="img/icons/menu/watch.svg">
                </a>
                ${v[2].title}
              </div>
            </div>
            <div class="columns">
              <div class="column">
                <a class="thumb" href="${v[3].videoLink}">
                  <img class="vthumb" src="${v[3].thumb}">
                  <img class="play" src="img/icons/menu/watch.svg">
                </a>
                ${v[3].title}
              </div>
              <div class="column">
                <a class="thumb" href="${v[4].videoLink}">
                  <img class="vthumb" src="${v[4].thumb}">
                  <img class="play" src="img/icons/menu/watch.svg">
                </a>
                ${v[4].title}
              </div>
              <div class="column">
              </div>
            </div>
          </div>
`;
}
