// Home template
// data = "messages"
export default function homeTemplate(data, industry) {
  return `
          <div class="container is-fluid">

            <div class="home-deco"></div>

            <div class="title">
              <h1>${data[0].title}</h1>
              <p>${data[0].message}</p>
            </div>

            <div class="columns home-columns is-mobile">
              <div class="column pd-col">
                <a href="#">
                  <div id="home-pd-bgr"></div>
                  <div class="circle"></div>
                  <div class="text">${data[2].title}</div>
                </a>
              </div>
              <div class="column dt-col">
                <a href="#">
                  <div class="circle"></div>
                  <div class="center"><div class="text">${data[1].title}</div></div>
                </a>
              </div>
              <div class="column ce-col">
                <a href="#">
                  <div id="home-ce-bgr"></div>
                  <div class="circle"></div>
                  <div class="text">${data[3].title}</div>
                </a>
              </div>
            </div>

          </div>
  `;
}
