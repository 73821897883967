// Start the intro screen animation right away
import gsap from 'gsap';
gsap.to('#section-intro img', { duration: 10, scale: 1.5, ease: 'none' })


//=== Import the modules
import Navigation from './js/navigation';     // Main

//=== Import styles and favicon
import './main.sass';                         // Main sass file
import './img/favicon.png';                   // Copy the favicon



// Copy the dt icons to dist
import './img/icons/dt/cloud.svg';
import './img/icons/dt/decision.svg';
import './img/icons/dt/ia.svg';
import './img/icons/dt/iot.svg';
import './img/icons/dt/model.svg';
import './img/icons/menu/x.svg';
import './img/icons/menu/play.svg';
import './img/icons/menu/language.svg';
import './img/icons/menu/down-arrow.svg';

import './img/decorative/pd-top.png';
import './img/decorative/pd-item.png';
import './img/decorative/ce-top.png';
import './img/decorative/ce-item.png';


import './img/sections-backgrounds/ConsumerGoods_CE_Act.png';
import './img/sections-backgrounds/ConsumerGoods_CE_Decide.png';
import './img/sections-backgrounds/ConsumerGoods_CE_Hero.png';
import './img/sections-backgrounds/ConsumerGoods_CE_Listen.png';
import './img/sections-backgrounds/ConsumerGoods_CE_Plan.png';
import './img/sections-backgrounds/ConsumerGoods_CE_Understand.png';
import './img/sections-backgrounds/ConsumerGoods_PD_Evaluate.png';
import './img/sections-backgrounds/ConsumerGoods_PD_Fulfill.png';
import './img/sections-backgrounds/ConsumerGoods_PD_Hero.png';
import './img/sections-backgrounds/ConsumerGoods_PD_Plan.png';
import './img/sections-backgrounds/ConsumerGoods_PD_Predict.png';
import './img/sections-backgrounds/ConsumerGoods_PD_Sell.png';
import './img/sections-backgrounds/DTC_CE_Act.png';
import './img/sections-backgrounds/DTC_CE_Decide.png';
import './img/sections-backgrounds/DTC_CE_Hero.png';
import './img/sections-backgrounds/DTC_CE_Listen.png';
import './img/sections-backgrounds/DTC_CE_Plan.png';
import './img/sections-backgrounds/DTC_CE_Understand.png';
import './img/sections-backgrounds/DTC_PD_Fulfill.png';
import './img/sections-backgrounds/DTC_PD_Hero.png';
import './img/sections-backgrounds/DTC_PD_Negotiate.png';
import './img/sections-backgrounds/DTC_PD_Plan.png';
import './img/sections-backgrounds/DTC_PD_Predict.png';
import './img/sections-backgrounds/DTC_PD_Sell.png';
import './img/sections-backgrounds/Grocery_CE_Act.png';
import './img/sections-backgrounds/Grocery_CE_Listen.png';
import './img/sections-backgrounds/Grocery_CE_Plan.png';
import './img/sections-backgrounds/Grocery_CE_Understand.png';
import './img/sections-backgrounds/Grocery_CE_Decide.png';
import './img/sections-backgrounds/Grocery_CE_Hero.png';
import './img/sections-backgrounds/Grocery_PD_Fulfill.png';
import './img/sections-backgrounds/Grocery_PD_Hero.png';
import './img/sections-backgrounds/Grocery_PD_Negotiate.png';
import './img/sections-backgrounds/Grocery_PD_Plan.png';
import './img/sections-backgrounds/Grocery_PD_Predict.png';
import './img/sections-backgrounds/Grocery_PD_Sell.png';
import './img/sections-backgrounds/Retail_CE_Act.png';
import './img/sections-backgrounds/Retail_CE_Decide.png';
import './img/sections-backgrounds/Retail_CE_Hero.png';
import './img/sections-backgrounds/Retail_CE_Listen.png';
import './img/sections-backgrounds/Retail_CE_Plan.png';
import './img/sections-backgrounds/Retail_CE_Understand.png';
import './img/sections-backgrounds/Retail_PD_Fulfill.png';
import './img/sections-backgrounds/Retail_PD_Hero.png';
import './img/sections-backgrounds/Retail_PD_Negotiate.png';
import './img/sections-backgrounds/Retail_PD_Plan.png';
import './img/sections-backgrounds/Retail_PD_Predict.png';
import './img/sections-backgrounds/Retail_PD_Sell.png';


//=== Run the modules
const navigation = new Navigation();
