import NodeFromString from './utils/node-from-string';

import navCETemplate from './templates/nav-ce-template';
import navPDTemplate from './templates/nav-pd-template';
import settingsTemplate from './templates/settings-template';
import menuTemplate from './templates/menu-template';
import homeTemplate from './templates/home-template';
import digitalTransformationTemplate from './templates/digital-transformation-template';
import productDemandTemplate from './templates/product-demand-template';
import customerEngagementTemplate from './templates/customer-engagement-template';
import lmVideoTemplate from './templates/lm-video-template';


// Create and attach a dom node
export default class BuildSections {
  constructor(sectionElems, industry, data) {
    // console.log(sectionElems);
    // console.log(settingsTemplate);

    let navRingsDiv = document.querySelector('#nav-rings');
    new NodeFromString(navRingsDiv, navCETemplate, industry, data);
    new NodeFromString(navRingsDiv, navPDTemplate, industry, data);
    new NodeFromString(sectionElems[0], settingsTemplate, industry, data.settings);
    new NodeFromString(sectionElems[1], menuTemplate, industry, data.settings);
    new NodeFromString(sectionElems[2], homeTemplate, industry, data.messages);
    new NodeFromString(sectionElems[3], digitalTransformationTemplate, industry, data);
    new NodeFromString(sectionElems[4], productDemandTemplate, industry, data);
    new NodeFromString(sectionElems[5], customerEngagementTemplate, industry, data);

    let ovlmWrapperElem = document.querySelector('#lightbox-lm .wrapper');
    new NodeFromString(ovlmWrapperElem, lmVideoTemplate, industry, data);

  }


  // constructor(elem, template, data) {
  //   elem.appendChild( htmlToElement( template(data) ) );
  // }

  rebuild(sectionElems, industry, data) {
    new NodeFromString(sectionElems[4], productDemandTemplate, industry, data);
    new NodeFromString(sectionElems[5], customerEngagementTemplate, industry, data);
  }
}
