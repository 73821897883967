// Menu template
// data = "settings"
export default function menuTemplate (data) {
  return `
          <div class="container">
            <div class="columns is-mobile">
              <div class="column">
                <div class="select-industry-container">
                  <div class="select-industry">

                    <div id="menu-circles">
                      <div class="circle"></div>
                      <div class="circle"></div>
                      <div class="circle"></div>
                      <div class="circle"></div>
                      <div class="circle"></div>
                    </div>

                    <p>${data.clickToBeginText}</p>
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="menu-links">
                  <a href="#" data-industry="${data.industries[0]}">${data.industries[0]}</a>
                  <a href="#" data-industry="${data.industries[1]}">${data.industries[1]}</a>
                  <a href="#" data-industry="${data.industries[2]}">${data.industries[2]}</a>
                  <a href="#" data-industry="${data.industries[3]}">${data.industries[3]}</a>
                </div>
              </div>
            </div>
          </div>
  `;
}
